<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Disciplina</th>
                        <th>Nível e material</th>
                        <th>Avaliado por</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(l, i) in levels.lista" :key="i">
                        <td><strong>{{l.disciplina}}</strong></td>
                        <td>
                            {{l.nivel}}<br v-if="l.serial" />
                            <small v-if="l.serial"><strong>Serial:</strong> {{l.serial}}</small>
                        </td>
                        <td>{{l.usuario_cadastro}}</td>
                        <td>{{dataPt(l.datahora_cadastro)}}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>

import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "AlunoNivel",
    props : ['id_aluno'],
    data() {
        return {
            carregando : false,
            levels : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['apiUrl', 'paginationLimit'])
    },
    methods : {
        paginar() {
            this.carregando = true
            return axios.post(`${this.apiUrl}aluno/rel_nivel_listar`, {
                id_aluno : parseInt(this.id_aluno)
            }).then( (res) => {
                this.levels = res.data
                this.carregando = false
            })
        }
    },
    watch : {
        'id_aluno' : function () {
            this.paginar()
        }
    },
    activated() {
        this.paginar()
    },
    created() {
        this.paginar()
    }
}
</script>

<style scoped>

</style>
