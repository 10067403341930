<template>
    <v-row>
        <v-col cols="12">
            <v-btn :to="`/aluno/nivel_incluir/${this.id_aluno}`" text><v-icon left>mdi-plus</v-icon>Incluir nível e material</v-btn>
        </v-col>
        <v-col cols="12" class="text-center">
            <v-dialog v-if="aluno.imagem_perfil" :transition="dialogTransition" v-model="dialogFoto" width="auto" :overlay-opacity="dialogOverlayOpacity">
                <template v-slot:activator="{ on, attrs }">
                    <img v-bind="attrs" v-on="on" style="width: 150px; border-radius:50%; border:5px solid #FFFFFF; cursor: pointer;" v-auth-image="`${apiUrl}usuario/${aluno.id_usuario}/imagem/thumb.jpg`"/>
                </template>
                <v-card><img style="width: 100%; max-width: 500px;" v-auth-image="`${apiUrl}usuario/${aluno.id_usuario}/imagem/ampliada.jpg`"/></v-card>
            </v-dialog>
            <!--<Avatar size="150" v-else :label="usuario.usuario" style="border: 5px solid #FFFFFF;"/>-->
            <h1 class="title pt-4">{{aluno.nome_completo}}</h1>
            <p class="text--primary mt-2">Gerencie as informações do aluno neste local, tais como: níveis, materiais, presenças e dados complementares.</p>
        </v-col>
        <v-col cols="12" sm="4">
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-toolbar color="primary" dark flat height="auto">
                            <v-toolbar-title class="py-2">Aluno</v-toolbar-title>
                            <v-spacer />
                            <!--Criar opção para provas credenciamento aqui-->
                            <v-btn v-if="permissao('aluno_editar')" exact :to="`/aluno/alterar/${id_aluno}`" icon class="py-4"><v-icon>mdi-pencil</v-icon></v-btn>
                            <v-btn v-if="permissao('aluno_excluir')" exact @click="excluir" icon class="py-4"><v-icon>mdi-delete</v-icon></v-btn>
                            <v-menu max-width="270" offset-y>
                                <template v-slot:activator="{on}">
                                    <v-btn exact icon v-on="on" class="py-4"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item @click="converterAluno(2)" exact target="_blank">
                                        <v-list-item-content>
                                            <v-list-item-title>Converter aluno em franqueado</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="converterAluno(4)" exact target="_blank">
                                        <v-list-item-content>
                                            <v-list-item-title>Converter aluno em professor</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="converterAluno(3)" exact target="_blank">
                                        <v-list-item-content>
                                            <v-list-item-title>Converter aluno em secretaria</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-btn to="/aluno" exact icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                        </v-toolbar>
                        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2"><strong>Aluno:</strong> {{aluno.nome_completo}}</p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2"><strong>ID:</strong> {{aluno.id_aluno}}</p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2"><strong>CPF:</strong> {{aluno.cpf}}</p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2"><strong>Responsável:</strong> <router-link :to="`/responsavel/gerenciar/${aluno.id_responsavel}`">{{aluno.responsavel_razao_social_nome_completo}}</router-link></p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2" v-if="aluno.estado_civil"><strong>Estado Civil:</strong> {{aluno.estado_civil}}</p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2" v-if="aluno.sexo"><strong>Sexo:</strong> {{aluno.sexo == 'm' ? "Masculino" : "Feminino"}}</p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2" v-if="aluno.data_nascimento"><strong>Nascimento:</strong> {{dataPt(aluno.data_nascimento)}}</p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2"><strong>Celular:</strong> {{aluno.celular}}</p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2" v-if="aluno.email"><strong>E-mail:</strong> {{aluno.email}}</p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2"><strong>Endereço:</strong> {{aluno.logradouro}} {{aluno.numero ? 'Nº' : ''}} {{aluno.numero}}, {{aluno.bairro}} - {{aluno.cidade}} {{aluno.uf}}</p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2"><strong>CEP:</strong> {{aluno.cep}}</p></v-col>
                                <v-col cols="12" class="py-0"><p class="text--primary mb-2" v-if="aluno.observacao"><strong>Observação:</strong> {{aluno.observacao}}</p></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" v-if="usuarioSessao.id_grupo === 1">
                    <v-card>
                        <v-toolbar color="primary" dark flat height="auto">
                            <v-toolbar-title class="py-2">Livros PDF</v-toolbar-title>
                            <v-spacer />
                        </v-toolbar>
                        <v-card-text class="mt-2">
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-btn @click="livroAtivarInativar(1, false)" small text v-if="aluno.livro1"><v-icon color="green" left>mdi-check-circle</v-icon>Novice - New Energy</v-btn>
                                    <v-btn @click="livroAtivarInativar(1, true)" small text v-else><v-icon color="red" left>mdi-close-circle</v-icon>Novice - New Energy</v-btn>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-btn @click="livroAtivarInativar(2, false)" small text v-if="aluno.livro2"><v-icon color="green" left>mdi-check-circle</v-icon>Intermediate - New Vision</v-btn>
                                    <v-btn @click="livroAtivarInativar(2, true)" small text v-else><v-icon color="red" left>mdi-close-circle</v-icon>Intermediate - New Vision</v-btn>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-btn @click="livroAtivarInativar(3, false)" small text v-if="aluno.livro3"><v-icon color="green" left>mdi-check-circle</v-icon>Advanced - New Decision</v-btn>
                                    <v-btn @click="livroAtivarInativar(3, true)" small text v-else><v-icon color="red" left>mdi-close-circle</v-icon>Advanced - New Decision</v-btn>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-btn @click="livroAtivarInativar(4, false)" small text v-if="aluno.livro4"><v-icon color="green" left>mdi-check-circle</v-icon>Advanced High - New Lifestyle</v-btn>
                                    <v-btn @click="livroAtivarInativar(4, true)" small text v-else><v-icon color="red" left>mdi-close-circle</v-icon>Advanced High - New Lifestyle</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="8">
            <v-tabs v-model="tab" class="elevation-3" show-arrows dark background-color="primary">
                <v-tab>Franquias</v-tab>
                <v-tab>Nível e material</v-tab>
                <v-tab>Relatório de presença</v-tab>
                <v-tab>Relatório de conteúdos e frases</v-tab>
                <!--<v-tab>* Objetivos</v-tab>
                <v-tab>* Cursos</v-tab>
                <v-tab>* Aulas</v-tab>
                <v-tab>* Turmas</v-tab>
                <v-tab>* Professores</v-tab>
                <v-tab>* Vocabulário</v-tab>
                <v-tab>* Disciplinas</v-tab>
                <v-tab>* Financeiro</v-tab>
                <v-tab>* Contratos</v-tab>-->
            </v-tabs>
            <v-tabs-items v-model="tab" class="elevation-3">
                <v-tab-item>
                    <v-divider/>
                    <v-simple-table v-if="aluno.franquia != undefined">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th style="width: 20px;"></th>
                                <th>Franquia</th>
                                <th style="width: 100px;"></th>
                                <th style="width: 100px;" v-if="usuarioSessao.id_grupo == 1"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(f, i) in aluno.franquia" :key="i">
                                <td class="pr-0">
                                    <v-avatar size="40" :color="corAvatar(f.franquia)">
                                        <span class="white--text headline">{{f.franquia.substr(0, 1)}}</span>
                                    </v-avatar>
                                </td>
                                <td :key="f.id_aluno">
                                    <router-link exact color="primary" v-if="permissao('franquia_abrir')" :to="`/franquia/gerenciar/${f.id_franquia}`"><strong>{{f.franquia}}</strong></router-link>
                                    <strong v-else>{{f.franquia}}</strong><br />
                                    <small><strong>ID:</strong> {{f.id_franquia}}</small>
                                </td>
                                <td>
                                    <v-btn @click="alunoInativar(f.id_franquia)" small text v-if="f.ativo"><v-icon color="green" left>mdi-check-circle</v-icon>Aluno ativo</v-btn>
                                    <v-btn @click="alunoAtivar(f.id_franquia)" small text v-else><v-icon color="red" left>mdi-close-circle</v-icon>Aluno inativo</v-btn>
                                </td>
                                <td v-if="usuarioSessao.id_grupo == 1">
                                    <v-btn @click="materialInativar(f.id_franquia)" small text v-if="f.exibir_material"><v-icon color="green" left>mdi-check-circle</v-icon>Material exibido</v-btn>
                                    <v-btn @click="materialAtivar(f.id_franquia)" small text v-else><v-icon color="red" left>mdi-close-circle</v-icon>Material oculto</v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <AlunoNivel :id_aluno="this.id_aluno"/>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <v-tabs v-model="tabRelatorioPresenca">
                        <v-tab v-for="(rel, ind) in relatorioAula" :key="ind">{{rel.turma}}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabRelatorioPresenca" class="elevation-0">
                        <v-tab-item v-for="(rel, ind) in relatorioAula" :key="ind">
                            <v-divider class="mt-2"/>
                            <v-simple-table dense fixed-header>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Conteúdo</th>
                                            <th><v-icon color="green">mdi-check</v-icon></th>
                                            <th v-for="(ra, i) in rel.relatorio.aulas" :key="i">{{ra.dia_mes}}</th>
                                            <th><v-icon color="green">mdi-check</v-icon></th>
                                            <th><v-icon color="red">mdi-close</v-icon></th>
                                            <th><v-icon color="green">mdi-percent</v-icon></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr style="color: #444;" v-for="(c, i) in rel.relatorio.conteudos" :key="i">
                                            <td><strong>{{c.conteudo}}</strong></td>
                                            <td><strong>{{c.conteudo_dado_total}}x</strong></td>
                                            <td v-for="(a, i) in c.aulas" :key="i">
                                                <v-icon v-if="a.aluno_presente == 't'" :style="{backgroundColor : a.conteudo_dado == 't' ? '#EBF3E1' : 'none'}" color="green">mdi-check</v-icon>
                                                <v-icon v-else-if="a.conteudo_dado == 't'" :style="{backgroundColor : a.conteudo_dado == 't' ? '#EBF3E1' : 'none'}" color="red">mdi-close</v-icon>
                                            </td>
                                            <td><strong>{{c.aulas.filter((v) => {return v.aluno_presente == 't'}).length}}x</strong></td>
                                            <td><strong>{{c.conteudo_dado_total - (c.aulas.filter((v) => {return v.aluno_presente == 't'}).length)}}x</strong></td>
                                            <td><strong>{{numero((c.aulas.filter((v) => {return v.aluno_presente == 't'}).length / c.conteudo_dado_total) * 100)}}%</strong></td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-divider class="my-3"/>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <v-tabs v-model="tabRelatorioSeiNaoSei">
                        <v-tab v-for="(rel, ind) in relatorioSeiNaoSei" :key="ind">{{rel.turma}}</v-tab>
                    </v-tabs>
                    <v-divider/>
                    <v-tabs-items v-model="tabRelatorioSeiNaoSei" class="elevation-0">
                        <v-tab-item v-for="(rel, ind) in relatorioSeiNaoSei" :key="ind">
                            <v-expansion-panels>
                                <v-expansion-panel v-for="(r,ri) in rel.relatorio" :key="ri">
                                    <v-expansion-panel-header class="pa-0">
                                        <v-card elevation="0" :style="`border-left: 5px solid ${r.conteudo_concluido == r.conteudo_total ? '#3FA243' : '#ffc107'}`">
                                            <v-card-title>{{r.nivel}}<v-spacer/> {{r.conteudo_concluido}}/{{r.conteudo_total}} | {{numero((r.conteudo_concluido/r.conteudo_total)*100,1)}}%</v-card-title>
                                        </v-card>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col cols="12" md="6" xl="4" v-for="(c, ci) in r.conteudo" :key="ci">
                                                <v-card elevation="4">
                                                    <v-progress-linear height="2" :value="(c.sei_total/c.frase_total)*100" :color="'#AAAAAA'"/>
                                                    <v-card-title :style="`border-left: 5px solid ${c.nao_sei_total == 0 ? '#3FA243' : '#F44336'}`">
                                                        {{c.conteudo}}
                                                        <v-spacer/>
                                                        <span>{{c.sei_total}}/{{c.frase_total}} | {{numero((c.sei_total/c.frase_total)*100,1)}}%</span>
                                                    </v-card-title>
                                                    <v-divider/>
                                                    <v-simple-table dense fixed-header>
                                                        <template v-slot:default>
                                                            <thead>
                                                                <tr>
                                                                    <th>Frase</th>
                                                                    <th>Traduções</th>
                                                                    <th>Conclusões</th>
                                                                    <th>Memorizado</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style="color: #444;" v-for="(f, fi) in c.frases" :key="fi">
                                                                    <td :style="`border-left: 5px solid ${f.memorizado == 't' ? '#3FA243' : '#F44336'};`">{{f.resposta}}</td>
                                                                    <td>{{f.nao_sei_total}}x</td>
                                                                    <td>{{f.sei_total}}x</td>
                                                                    <td>
                                                                        <v-icon v-if="f.memorizado == 't'" color="green">mdi-check</v-icon>
                                                                        <v-icon v-else color="red">mdi-close</v-icon>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tab-item>


                <!--<v-tab-item>
                    <v-divider/>
                    <CursoListar/>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <AulaListar/>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <TurmaListar/>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <UsuarioListar/>
                </v-tab-item>

                <v-tab-item>
                    <v-divider/>
                    /* Listar palavras buscadas da disciplina em questão */
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <DisciplinaListar/>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <FinanceiroReceberListar/>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    /* Listar contratos dos alunos (Dar uma olhada na plataforma d4sign.com.br) */
                </v-tab-item>-->
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>

/*import CursoListar from "@/Views/Curso/Component/CursoListar"
import AulaListar from "@/Views/Aula/Component/AulaListar"
import TurmaListar from "@/Views/Turma/Component/TurmaListar"
import UsuarioListar from "@/Views/Usuario/Component/UsuarioListar"
import FranquiaListar from "@/Views/Franquia/Component/FranquiaListar"
import DisciplinaListar from "@/Views/Disciplina/Component/DisciplinaListar"
import FinanceiroReceberListar from "@/Views/Financeiro/Component/FinanceiroReceberListar"*/
import {mapState} from "vuex"
import axios from "axios"
import AlunoNivel from "@/Views/Aluno/Component/AlunoNivel.vue"

export default {
    name: "AlunoGerenciar",
    components: {
        AlunoNivel,
        //FinanceiroReceberListar,
        //DisciplinaListar, FranquiaListar, UsuarioListar, TurmaListar, AulaListar, CursoListar
    },
    props : ['id_aluno'],
    data() {
        return {
            tab : null,
            carregando : false,
            tabRelatorioPresenca : null,
            tabRelatorioSeiNaoSei : null,
            aluno : {},
            relatorioAula : {},
            relatorioSeiNaoSei : {}
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl', 'usuarioSessao'])
    },
    methods : {
        converterAluno(id_grupo) {
            return axios.post(`${this.apiUrl}aluno/converter_usuario`, {id_grupo, id_aluno : parseInt(this.id_aluno)}).then( (res) => {
                if(res.data) {
                    alert('Aluno convertido com sucesso')
                } else {
                    alert('Erro ao converter aluno')
                }
            })
        },
        get() {
            this.carregando = true
            return axios.post(`${this.apiUrl}aluno/get`, {id_aluno : parseInt(this.id_aluno)}).then( (res) => {
                this.aluno = res.data
                this.carregando = false
            })
        },
        getRelatorioParticipacao() {
            return axios.post(`${this.baseUrl}aluno/relatorio_participacao`, {id_aluno : this.id_aluno}).then( (res) => {
                this.relatorioAula = res.data
            })
        },
        getRelatorioSeiNaoSei() {
            return axios.post(`${this.baseUrl}aluno/relatorio_sei_nao_sei`, {id_aluno : this.id_aluno}).then( (res) => {
                this.relatorioSeiNaoSei = res.data
            })
        },
        excluir() {
            let c = confirm("Essa ação não pode ser desfeita, tem certeza que deseja excluir?")
            if(c) {
                return axios.post(`${this.baseUrl}aluno/excluir`, {id_aluno : this.id_aluno}).then( (res) => {
                    if (res.data) {
                        this.$router.push({path:'/aluno'})
                    } else {
                        alert("Erro ao excluir registro")
                    }
                })
            }
        },
        alunoAtivar(id_franquia) {
            this.carregando = true
            return axios.post(`${this.apiUrl}franquia/aluno/ativar`, {id_franquia, id_aluno : parseInt(this.id_aluno)}).then( () => {
                this.get()
            })
        },
        alunoInativar(id_franquia) {
            this.carregando = true
            return axios.post(`${this.apiUrl}franquia/aluno/inativar`, {id_franquia, id_aluno : parseInt(this.id_aluno)}).then( () => {
                this.get()
            })
        },
        materialAtivar(id_franquia) {
            this.carregando = true
            return axios.post(`${this.apiUrl}aluno/material/exibir`, {id_franquia, id_aluno : parseInt(this.id_aluno), exibir : true}).then( () => {
                this.get()
            })
        },
        materialInativar(id_franquia) {
            this.carregando = true
            return axios.post(`${this.apiUrl}aluno/material/exibir`, {id_franquia, id_aluno : parseInt(this.id_aluno), exibir : false}).then( () => {
                this.get()
            })
        },
        livroAtivarInativar(id_livro, exibir) {
            this.carregando = true
            return axios.post(`${this.apiUrl}aluno/livro/exibir`, {id_livro, id_aluno : parseInt(this.id_aluno), exibir : exibir}).then( () => {
                this.get()
            })
        }
    },
    async activated() {
        await this.get()
        await this.getRelatorioParticipacao()
        await this.getRelatorioSeiNaoSei()
    }
}
</script>

<style scoped>

</style>
